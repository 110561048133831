import React, { Dispatch, FC, useCallback, useEffect, useRef, useState } from "react";

// Import Swiper React components
import { StyledSwiper, GalleryContainer, ZoomedContainer, CustomDiv } from "./CarouselHDMDesktopStyles";
import { SwiperSlide } from "swiper/react";
import { Zoom, Navigation, Pagination, Manipulation, Thumbs } from "swiper";
import SwiperClass from "swiper";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/zoom/zoom.min.css";
import "swiper/modules/thumbs/thumbs.min.css";

// Icons
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as ViewInArIcon } from "../../../assets/homedepot/images/viewInArBlack.svg";

// UI
import ReactImageMagnify from "react-image-magnify";
import VisualSupport from "../styled-visual-support/VisualSupport";
import { StyledBox, StyledTypography, StyledCardMediaHipoteca } from "../../../hdm";
import useScript from "./useScript";

import { useDispatch, useSelector } from "react-redux";
import { onlineStoreRdc } from "../../../redux/selectors/hdmData";
import { SLPDPProductImageCard } from "../skeleton-loader/components/elements/PDP";
import { CORA_BUNDLE_AR_JS } from "../../../constants/common";
import { useLocation } from "react-router";
import { FETCH_HDM_DATA_REQUESTED } from "../../../redux/action-types/hdmData";

const AddScriptAr = () => {
  
  useScript(CORA_BUNDLE_AR_JS, "");

  return null
}

interface ICarouselHDMProps {
  fullImage: string;
  carouselImages?: string[] | string;
  activeSlide: number;
  setActiveSlide: React.Dispatch<React.SetStateAction<number>>;
  setHasBeenRendered: React.Dispatch<React.SetStateAction<boolean>>;
  isVisualSupportEnabled: string;
  ArApiKey: string;
  SKU: string;
  promociones: any;
  urlRibbons: any;
  isArEnabled: boolean;
}
let isSortingImages: boolean = false;
let zoomedProductFullImage: string | undefined= "";
let zoomImages: string[] = [];
let associatedImages: string[] = [];
let terminationDimages: string[] = [];
const CarouselHDMDesktop: FC<ICarouselHDMProps> = ({
  carouselImages = [],
  setHasBeenRendered,
  isVisualSupportEnabled,
  ArApiKey,
  SKU,
  promociones,
  urlRibbons,
  isArEnabled,
}) => {
  /* States */
  const [attachedImages, setattachedImages] = useState<string[] | string>([]);
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
  const [numError, setNumError] = useState<string[] | string>([]);
  const [isCheckingImges, setisCheckingImges] = useState<boolean>(false);
  const [isCheckingImages, setIsCheckingImages] = useState<boolean>(false);
  const [imgNotFounds, setImgNotFounds] = useState<any>(null);
  const [showComponent, setShowComponent] = useState<any>(false);
  const dispatch = useDispatch<Dispatch<any>>();

  const removeErrorImage = (errorImage: any): any => {
    // swiperRef?.current?.removeSlide(slideIndex - 1)
    errorImage?.remove();
    swiperRef?.current?.updateSlides();
    delete result[errorImage];
  };

  const swiperRef = useRef<SwiperClass>();

  /*-- Carousel images functions --*/
  const storeConfData = useSelector(onlineStoreRdc);
  const baseUrlImagesHDM = storeConfData?.IMAGE_SERVER_HOSTNAME;

  /* Carousel images */
  const productFullImage = baseUrlImagesHDM + "productos/" + SKU + "/" + SKU + "-m.jpg";
  const productFullImageDefault = baseUrlImagesHDM + "productos/" + SKU + "/" + SKU + "-d.jpg";

  // set attached images to the state
  useEffect(() => {
    if (carouselImages.length > 0) {
      setattachedImages(carouselImages);
      setisCheckingImges(true);
      zoomImages = [];
      associatedImages = [];
      terminationDimages =[];
      isSortingImages = false;
      zoomedProductFullImage = "";
    }
    //If attachedImages still empty and SKU has some value
    else if(attachedImages.length === 0 && SKU && SKU !== ""){
      //Set the main image of the product as unique product in gallery
      const singleImage : string[] = [];
      singleImage.push("productos/" + SKU + "/" + SKU + "-m.jpg")
      setattachedImages(singleImage);
      setisCheckingImges(true);
      setImgNotFounds([]);
    }
  }, [carouselImages]);

  useEffect(() => {
    if (isCheckingImges) {
      const imagesForCheck: string[] = [];
      for (const image of attachedImages) {
        const splittedImage = image.split("-")[1].split(".")[0].slice(0, 1);
        if (splittedImage === "a") {
          imagesForCheck.push(baseUrlImagesHDM + image);
        }
      }
      checkImagesIfExists(imagesForCheck);
    }
  }, [isCheckingImges]);

  const checkImagesIfExists = useCallback(async (imagesForCheck) => {
    const imgNotExist: any = [];

    await Promise.all(
      imagesForCheck.map((urlFile, index) => {
        const xhr = new XMLHttpRequest();
        // listen for `onload` event
        xhr.onload = () => {
          if (xhr.status == 200) {
            if (index === imagesForCheck?.length - 1) {
              setImgNotFounds(imgNotExist);
            }
          } else {
            imgNotExist.push(urlFile);
            if (index === imagesForCheck?.length - 1) {
              setImgNotFounds(imgNotExist);
            }
          }
        };

        xhr.onerror = function () {
          if (index === imagesForCheck?.length - 1) {
            setImgNotFounds(imgNotExist);
          }
        };

        // create a `HEAD` request
        xhr.open("HEAD", urlFile);
        // send request
        xhr.send();
      })
    );
    setIsCheckingImages(true)
  }, [attachedImages]);


  const sortImagesByNom = useCallback(() => {
    for (const image of attachedImages) {
      const splittedImage = image.split("-")[1].split(".")[0].slice(0, 1);
      if (splittedImage === "z") {
        // console.log('splittedImage', splittedImage)
        if (image.includes("-z.jpg")) {
          zoomImages.push(baseUrlImagesHDM + image.replace("-z", "-d"));
        } else {
          zoomImages.push(baseUrlImagesHDM + image);
        }
      }
      if (splittedImage === "a") {
        associatedImages.push(baseUrlImagesHDM + image);
      }
      if (splittedImage === "p") {
        terminationDimages.push(baseUrlImagesHDM + image);
      }
    }
    isSortingImages = true;
    setIsCheckingImages(false)
  }, [attachedImages]);
  if (isCheckingImages) {
    sortImagesByNom();
  }

  if (isSortingImages && zoomImages) {
      // sort images alphabeticaly
      zoomedProductFullImage = zoomImages.sort().shift();
      associatedImages.sort();
      terminationDimages.sort();
      isSortingImages = false;
  }
  // sortImagesByNom2();



  // Merge images into an object to be used on carousel
  const result = {};
  const resultThumbnails = {};
  if (associatedImages) {
    associatedImages.forEach((element: string, index) => {
      result[element] = zoomImages[index];
    });

    associatedImages.forEach((element: string, index) => {
      resultThumbnails[element] = terminationDimages[index];
    });
  }

  const visualSupportHandler = (slideType: string) => {
    const slidesLength: number = swiperRef?.current?.slides?.length as number;

    if (slideType === "qr-view") {
      swiperRef?.current?.slideTo(slidesLength - 2, 0, false);
    }
    if (slideType === "ar-view") {
      swiperRef?.current?.slideTo(slidesLength, 0, false);
    }
  };

  // Call cora script if product has ar enabled

  const [imgRef, setImgRef] = useState<any>(null);

  useEffect(() => {
    const imgRefCurrent = imgRef;
    if (imgRefCurrent) {
      imgRefCurrent.addEventListener('load', 
      ()=>{
        setShowComponent(true)
      });
      return () => imgRefCurrent.removeEventListener('load', null);
    }
  }, [imgRef]);

  const location: any = useLocation();
  const producturl = location?.pathname?.replace("/p/","");


  if (!imgNotFounds) return <SLPDPProductImageCard/>;

  if (showComponent && Object.entries(result).length > 0) {
    setHasBeenRendered(true)
    const query = {
      url: "",
      data: true,
      option: "LOAD_GALLERY",
      fetch: false,
    };
    dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
  }
  return (
    <>
    {/*Do not remove the below img tag, it is used to measure when it is loaded and prevent large wait time of ReactImageMagnify*/}
    <img ref={setImgRef} style={{display:"none"}} src={productFullImage} ></img>
    {!showComponent && <SLPDPProductImageCard/>}
    
      <GalleryContainer className="gallery-container" sx={{ display: "flex" }}>
        {
          showComponent && thumbsSwiper &&
          <StyledSwiper
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            passiveListeners={false}
            navigation={{
              nextEl: ".nextButton",
              prevEl: ".prevButton",
              disabledClass: "hide-thumb-stepper",
            }}
            modules={[Zoom, Navigation, Pagination, Manipulation, Thumbs]}
            thumbs={{ swiper: thumbsSwiper }}
            className="main-swiper">
            <div className="test-boxi">
              <SwiperSlide>
                <div className="swiper-zoom-container">
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        alt: producturl,
                        isFluidWidth: true,
                        src: zoomedProductFullImage ? zoomedProductFullImage : productFullImageDefault,
                        onError: (e: any) => removeErrorImage(e?.currentTarget?.parentNode?.parentNode?.remove()),
                      },
                      largeImage: {
                        src: zoomedProductFullImage && zoomedProductFullImage.includes("-d") ? zoomedProductFullImage.replace("-d", "-z") : productFullImageDefault,
                        alt: producturl,
                        width: 1200,
                        height: 1200,
                        onError: (e: any) => removeErrorImage(e?.currentTarget?.parentNode?.parentNode?.remove())
                      },
                      // lensStyle: {
                      //     background: 'hsla(23, 100%, 55%, 0.6)',
                      // },
                      enlargedImagePortalId: "portalImage",
                      // imageClassName: "lazyload",
                      // enlargedImageClassName: "lazyload",
                    }}
                  />
                  {promociones && promociones.includes("PROMOICON_HV") ? (
                    <StyledCardMediaHipoteca
                      image={urlRibbons["PROMOICON_HV"]}
                      title={"hipoteca verde"}
                      className={"promo_plp-ribbon-hv-pdp"}
                      component="div"
                    />
                  ) : null}
                </div>
              </SwiperSlide>
            </div>

            <div className="test-boxi">
              {Object.entries(result).map((image: any, index) => {
                return (
                  <SwiperSlide key={index}>
                    <ReactImageMagnify
                      {...{
                        smallImage: {
                          alt: producturl,
                          isFluidWidth: true,
                          src: image[0],
                          onError: (e: any) => removeErrorImage(e?.currentTarget?.parentNode?.parentNode?.remove()),
                        },
                        largeImage: {
                          alt: producturl,
                          src: image[1] ? image[1] : image[0],
                          width: 1200,
                          height: 1200,
                          onError: (e: any) => removeErrorImage(e?.currentTarget?.parentNode?.parentNode?.remove()),
                        },
                        // lensStyle: {
                        //     background: 'hsla(23, 100%, 55%, 0.6)',
                        // },
                        enlargedImagePortalId: "portalImage",
                        imageClassName: "lazyload",
                        enlargedImageClassName: "lazyload",
                      }}
                    />
                    {promociones && promociones.includes("PROMOICON_HV") ? (
                      <StyledCardMediaHipoteca
                        image={urlRibbons["PROMOICON_HV"]}
                        title={"hipoteca verde"}
                        className={"promo_plp-ribbon-hv-pdp"}
                        component="div"
                      />
                    ) : null}
                  </SwiperSlide>
                );
              })}

              {/* {(isVisualSupportEnabled === "1" || isVisualSupportEnabled === "3") && isArEnabled && isArReady && ( */}
              {(isVisualSupportEnabled === "1" || isVisualSupportEnabled === "3") && isArEnabled && (
                <>
                  {/* QR slide */}
                  <SwiperSlide>
                    <StyledBox className="qr-container">
                      <SvgIcon>
                        <ViewInArIcon />
                      </SvgIcon>
                      <StyledTypography variant="headingTitle">
                        {"VISUALIZA ESTE PRODUCTO EN TU ESPACIO"}
                      </StyledTypography>
                      <StyledTypography variant="bodyBaseline">{"Escanéa el QR en tu celular"}</StyledTypography>
                      <div
                        id="cora-ar-qr-code"
                        data-sku={SKU}
                        data-token={ArApiKey}
                        lang="es"
                      // hidelogo
                      ></div>
                    </StyledBox>
                  </SwiperSlide>

                  {/* 360 model view slide */}
                  <SwiperSlide>
                    <StyledBox className="ar-viewer-container">
                      <CustomDiv
                        id="cora-ar-3d-viewer"
                        data-sku={SKU}
                        data-token={ArApiKey}
                        lang="es"
                        hidelogo=""
                        automaticdisplay=""></CustomDiv>
                    </StyledBox>
                  </SwiperSlide>
                </>
              )}
            </div>
          </StyledSwiper>
        }


        {/* Thumbs  */}
        <StyledSwiper
          modules={[Thumbs, Navigation]}
          watchSlidesProgress
          onSwiper={setThumbsSwiper}
          // preventInteractionOnTransition={true}
          allowTouchMove={imgNotFounds.length <= 1 && attachedImages.length !== 0 ? true : false}
          className="thumbs"
          slidesPerView={6}
          direction="vertical"
          centeredSlidesBounds>
          <SwiperSlide>
            <img
              src={productFullImage}
              alt={producturl}
              className="lazyload"
              onError={(e: any) => removeErrorImage(e?.currentTarget?.parentNode)}
            />
          </SwiperSlide>

          {Object.entries(resultThumbnails).map((image: any, index) => (
            <SwiperSlide key={index}>
              <img
                src={image[1] ? image[1] : image[0]}
                alt={producturl}
                className="lazyload"
                onError={(e: any) => {
                  setNumError([...numError, e.currentTarget]);
                  removeErrorImage(e?.currentTarget?.parentNode);
                }}
              />
            </SwiperSlide>
          ))}

          {/* {isVisualSupportEnabled === "1" &&(
            <>
              <SwiperSlide>
                <div>ar</div>
              </SwiperSlide>
              <SwiperSlide>
                <div>360</div>
              </SwiperSlide>
            </>  
          )} */}
        </StyledSwiper>

        {numError.length <= 1 && attachedImages.length !== 0 ? (
          <>
            <ExpandLessIcon className="prevButton" />
            <ExpandMoreIcon className="nextButton" />
          </>
        ) : null}

        {(isVisualSupportEnabled === "1" || isVisualSupportEnabled === "3") && isArEnabled && (
          <>
          <AddScriptAr  />
          </>
        )}

      </GalleryContainer>

      <ZoomedContainer>
        <div id="portalImage" className="zoomed-image"></div>
      </ZoomedContainer>

      {(isVisualSupportEnabled === "1" || isVisualSupportEnabled === "3") && (
        <>
          <VisualSupport
            visualSupportSlideHandler={visualSupportHandler}
            ArApiKey={ArApiKey}
            isArEnabled={isArEnabled}
            SKU={SKU}
          />

          {/* Used for displaying the QR and ar view */}
          {/* hidden coverlayer */}
          <CustomDiv
            id="coverlayer"
            hidecreativedrivelogo=""
            language="es"
            currency="MXN"
            accentcolor="#F96302"
            apitoken="6231e4310d2cdd0061c063cf"
            className="cover-layer-pdp"></CustomDiv>
        </>
      )}
    </>
  );
};

export default React.memo(CarouselHDMDesktop);
