import { RootReducerState } from "../reducers";

export const estadoCiudadesRdc = (state: RootReducerState) => state.hdmDataReducer.estadosCiudades;
export const exclisiveRdc = (state: RootReducerState) => state.hdmDataReducer.exclusive;
export const pdpQuantityRdc = (state: RootReducerState) => state.hdmDataReducer.pdpQuantity;
export const onlineStoreRdc = (state: RootReducerState) => state.hdmDataReducer.onlineStore;
export const paymentSequenceRdc = (state: RootReducerState) => state.hdmDataReducer.paymentSequence;
export const checkOutStepRdc = (state: RootReducerState) => state.hdmDataReducer.checkOutStep;
export const guestUserDataSelector = (state: RootReducerState) => state.hdmDataReducer.guestUserData;
export const deliveryMethodIdSelector = (state: RootReducerState) => state.hdmDataReducer.deliveryMethodId;
export const pdpSeoRdc = (state: RootReducerState) => state.hdmDataReducer.pdpSeo;
export const installationPartNumber = (state: RootReducerState) => state.hdmDataReducer.installationPartNumber;
export const cuentaMisOrdenesFiltrosRdc = (state: RootReducerState) => state.hdmDataReducer.cuentaMisOrdenesFiltros;
export const hdmStoresDataRdc = (state: RootReducerState) => state.hdmDataReducer.hdmStoresData;
export const hdmWishListSelectedProductsRdc = (state: RootReducerState) => state.hdmDataReducer.wishListSelectedProduct;
export const hdmWishListProductsOrderByRdc = (state: RootReducerState) => state.hdmDataReducer.wishListProductsOrderBy;
export const hdmWishListProductsQuantityRdc = (state: RootReducerState) => state.hdmDataReducer.wishListProductsQuantity;
export const wishListSearchProductRdc = (state: RootReducerState) => state.hdmDataReducer.wishListSearchProduct;
export const lineaGamaColorsRdc = (state: RootReducerState) => state.hdmDataReducer.lineaGamaColors;
export const hdmColorsRdc = (state: RootReducerState) => state.hdmDataReducer.hdmColors;
export const hdmDrawerColorDataRdc = (state: RootReducerState) => state.hdmDataReducer.hdmDrawerColorData;
export const hdmPDPproductDataRdc = (state: RootReducerState) => state.hdmDataReducer.hdmPDPproductData;
export const hdmBopisDrawerCart = (state: RootReducerState) => state.hdmDataReducer.bopisDrawerCart;
export const hdmOrderResumeOptData = (state: RootReducerState) => state.hdmDataReducer.orderResumeOptData;
export const hdmEmarsysSearchTagData = (state: RootReducerState) => state.hdmDataReducer.emarsysSearchTagData;
export const hdmEmarsysCategoryTagData = (state: RootReducerState) => state.hdmDataReducer.emarsysCategoryTagData;
export const hdmEmarsysPDPTagData = (state: RootReducerState) => state.hdmDataReducer.emarsysPDPTagData;
export const hdmEmarsysTYPTagData = (state: RootReducerState) => state.hdmDataReducer.emarsysTYPTagData;
export const hdmEmarsysPDPTagFinished = (state: RootReducerState) => state.hdmDataReducer.emarsysPDPTagFinished;
export const hdmEmarsysWidgetType= (state: RootReducerState) => state.hdmDataReducer.emarsysWidgetType;
export const hdmEmarsysAdditionalReady= (state: RootReducerState) => state.hdmDataReducer.emarsysAdditionalReady;
export const hdmEmarsysRegularReady= (state: RootReducerState) => state.hdmDataReducer.emarsysRegularReady;
export const hdmStoreBuyableExclude= (state: RootReducerState) => state.hdmDataReducer.storeBuyableExclude;
export const hdmIsGalleryLoaded= (state: RootReducerState) => state.hdmDataReducer.isGalleryLoaded;
export const hdmLoaderCheckoutStep= (state: RootReducerState) => state.hdmDataReducer.loaderCheckoutStep;
export const hdmFooterRoute= (state: RootReducerState) => state.hdmDataReducer.footerRoute;
export const hdmEmarsysBundleItemsReady= (state: RootReducerState) => state.hdmDataReducer.hdmEmarsysBundleItemsReady;
export const hdmCheckoutProfileSearchData= (state: RootReducerState) => state.hdmDataReducer.hdmCheckoutProfileSearchData;
export const hdmProductSCC= (state: RootReducerState) => state.hdmDataReducer.hdmProductSCC;
export const hdmProExpiredSession= (state: RootReducerState) => state.hdmDataReducer.hdmProExpiredSession;