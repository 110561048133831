import React, { ChangeEvent, useEffect } from 'react'
import { StyledSelectHdm } from '../styled-select'
import { StyledMenuItem, StyledTypography } from '../../../hdm';
import { StyledTooltipHdm } from '../styled-tooltip-hdm';

interface IPriceSelect {
    selectedValue: any;
    setSelectedValue: any;
    priceValues: any;
    setPriceValues: any;
    labelText: string;
    productDefaultPrice: string;
    sccPrices: any;
    handleChangeSCCselector: (value: any) => void;
    isSCCProductConvenio: any
    orderHasSCCSaved: boolean
}

export const SccPriceSelector = (
    {selectedValue, setSelectedValue, priceValues, setPriceValues, labelText, productDefaultPrice, sccPrices, handleChangeSCCselector, isSCCProductConvenio, orderHasSCCSaved}:IPriceSelect) => {
    
        const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            handleChangeSCCselector(event.target.value);
        };

        const formatPrice = (amount) => {
          return new Intl.NumberFormat("es-MX", {
            style: "currency",
            currency: "MXN",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(amount);
        }; 

      let sccPriceItems:any = [];
      const defaultPrice = parseFloat(productDefaultPrice).toFixed(2)
      let isDisabled = false
      if(!isSCCProductConvenio && orderHasSCCSaved){
        isDisabled = true
      }
        

      sccPriceItems = React.Children.toArray(
        sccPrices && sccPrices?.length > 0 ? (
            sccPrices?.map((scc, index) => (
            <StyledMenuItem value={scc.sccId} key={index} className={"styledSelect--menuItems-color"} fullWidth disabled={isDisabled} >
              {console.log("sccQQQ", scc)}
              <StyledTypography variant="bodyBaseline">
                {`${formatPrice(scc?.price)} (Max. ${scc?.quantity} piezas)`}
              </StyledTypography>
            </StyledMenuItem>
          ))
        ) : null);

        sccPriceItems.unshift(
            <StyledMenuItem value={defaultPrice} key={defaultPrice} className={"styledSelect--menuItems-color"} fullWidth >
            <StyledTypography variant="bodyBaseline">
              {`${formatPrice(defaultPrice)} (Antes)`}
            </StyledTypography>
          </StyledMenuItem> 
        )   

    useEffect(() => {
    
        if(sccPrices.length > 0){
            setPriceValues(sccPriceItems)
            setSelectedValue(isSCCProductConvenio ?? defaultPrice)
        }
        return () => {
            setSelectedValue(defaultPrice)
        }
    }, [])

    const toolTip = <StyledTooltipHdm content="eres gei"/>;


    return (
        <>
            <StyledSelectHdm
                label={labelText}
                name="agreement-value"
                value={selectedValue}
                items={priceValues}
                handleChange={handleChange}
                isSccSelector
            />
            
            {/* <StyledTooltipHdm content="eres gei"/> */}
            
        </>
    )
}