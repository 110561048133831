/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { put, select } from "redux-saga/effects";
//Redux
import * as ERRORS from "../../../constants/errors";
import {
  HANDLE_SESSION_ERROR_ACTION,
  RESET_ERROR_SUCCESS_ACTION,
  VALIDATION_ERROR_ACTION,
  RESET_SESSION_POPUP_LOGON_ERROR_ACTION,
} from "../../actions/error";
import { loginTokenAction } from "../../actions/user";
import { defaultStates } from "../../reducers/initStates";
import { sessionErrorSelector } from "../../selectors/error";
//Foundation libraries
import { getSite } from "../../../_foundation/hooks/useSite";
import { setCookie } from "../../../components/custom-components/styled-store-selector/cookieHandler";
import * as ACTIONSHDMDATA from "../../action-types/hdmData";
let handlingError = false;
/**
 * Saga worker to invoke get orders API
 */
export function* handleAxiosErrors(action: any) {
  const mySite = getSite();
  const { payload: error } = action;
  const isLogoff =
    error.config && error.config.url.endsWith("loginidentity/@self") && error.config.method.toLowerCase() === "delete";
  //ignore logoff error
  function resetHandlingError(){
    setTimeout(() => {
      if (handlingError) {
        handlingError = false;
      }
    }, 1000);
  }

  if (error.isAxiosError && !handlingError && !isLogoff) {
    handlingError = true;
    const errorResponse = error.response;
    if(errorResponse?.data.code === "INVALID_PARAMETER_VALUE"){
      resetHandlingError()
      return null
    }

    if (errorResponse && errorResponse.data && errorResponse.data.errors) {
      const e = errorResponse.data.errors[0];
      console.log("errorWorker", e);
      setCookie("WC_LogonUserId_" + (mySite && mySite.storeID?mySite.storeID:"10351"), "", 0);
      // handle expired session
      if (
        e.errorCode === ERRORS.EXPIRED_ACTIVITY_TOKEN_ERROR ||
        e.errorKey === ERRORS.EXPIRED_ACTIVITY_TOKEN_ERROR ||
        e.errorCode === ERRORS.ACTIVITY_TOKEN_ERROR_CODE ||
        e.errorKey === ERRORS.ACTIVITY_TOKEN_ERROR_KEY ||
        e.erroCode === ERRORS.COOKIE_EXPIRED_ERROR_CODE ||
        (mySite && mySite.isB2B && e.erroCode === ERRORS.NOT_AUTHORIZATION_ERROR_CODE) ||
        e.erroCode === "INVALID_PARAMETER_VALUE" ||
        e.errorKey === ERRORS.COOKIE_EXPIRED_ERROR_KEY
      ) {
        //timeout
        const payload = {
          ...e,
          handled: false,
          errorTitleKey: "SESSION_TIMEOUT",
          errorMsgKey: "SessionError.TimeoutMsg",
          showKeepLogin: e.errorCode === ERRORS.EXPIRED_ACTIVITY_TOKEN_ERROR ? true : false,
        };

        yield put(HANDLE_SESSION_ERROR_ACTION(payload));
        if(mySite?.isB2B){
          yield put({
            type: ACTIONSHDMDATA.FETCH_HDM_DATA_SUCCESS,
            payload: {
              option: "HDM_PRO_EXPIRED_SESSION",
              response: true
            }
          });
        }
      }
      // handle invalid session where another user logged in from different location
      else if (e.errorCode === ERRORS.INVALID_COOKIE_ERROR_CODE && e.errorKey === ERRORS.INVALID_COOKIE_ERROR_KEY) {
        const payload = {
          ...e,
          handled: false,
          errorTitleKey: "SESSION_TIMEOUT",
          errorMsgKey: mySite && mySite.isB2B ? "SessionError.InvalidMsg" : "SessionError.InvalidMsgB2C",
        };
        yield put(HANDLE_SESSION_ERROR_ACTION(payload));
      }
      // handle password expired issue.
      else if (e.errorCode === ERRORS.PASSWORD_EXPIRED_ERR_CODE || e.errorKey === ERRORS.PASSWORD_EXPIRED) {
        //reset password dialog

        const payload = {
          ...e,
          handled: false,
          errorTitleKey: "reset.title",
          errorMsgKey: "reset.errorMsg",
        };
        yield put(HANDLE_SESSION_ERROR_ACTION(payload));
      }
      // handle password expired issue.
      else if (
        e.errorCode === ERRORS.PARTIAL_AUTHENTICATION_ERROR_CODE ||
        e.errorKey === ERRORS.PARTIAL_AUTHENTICATION_ERROR_KEY
      ) {
        //remember me
        //reset password dialog

        const payload = {
          ...e,
          handled: false,
          errorTitleKey: "SESSION_PARTIAL_AUTH",
          errorMsgKey: "SessionError.PartialAuthError",
        };
        yield put(HANDLE_SESSION_ERROR_ACTION(payload));
      } else if (e.errorCode === ERRORS.TOKEN_REQUIRED_ERROR_CODE) {
        //token required

        const payload = {
          ...e,
          handled: false,
          errorTitleKey: "SessionError.TokenRequired",
          errorMsgKey: "SessionError.TokenRequiredError",
          isTokenRequired: true,
        };
        yield put(loginTokenAction(payload));
      } else if (e.errorCode === ERRORS.TOKEN_EXPIRED_ERROR_CODE) {
        const payload = {
          ...e,
          handled: false,
          errorTitleKey: "SessionError.TokenExpired",
          errorMsgKey: "SessionError.TokenExpiredError",
          isTokenRequired: true,
          isTokenExpired: true,
        };
        yield put(loginTokenAction(payload));
      } else if (e.errorCode === ERRORS.TOKEN_INVALID_ERROR_CODE) {
        //token required

        const payload = {
          ...e,
          handled: false,
          errorTitleKey: "SessionError.TokenRequired",
          errorMsgKey: "SessionError.TokenRequiredError",
          isTokenRequired: true,
          isTokenInvalid: true,
        };
        yield put(loginTokenAction(payload));
      } else {
        //other errors

        // Avoid orderitem delete error
        const isOrderItemUpdate =
          error.config && error.config.url && error.config.url.indexOf("@self/update_order_item") > -1;
        if (isOrderItemUpdate && e.errorCode == ERRORS.ORDERITEM_NOT_FUND_ERROR_CODE) {
          resetHandlingError()
          return true;
        }
        if (ERRORS.NOT_AUTHORIZED_GET_ERROR_CODE === e.errorCode) {
          resetHandlingError()
          return true;
        }

        //custom - hide snackbar error different frome above
        if (e?.errorCode !== "2080") {
          const payload = {
            ...e,
            handled: false,
            errorTitleKey: "SessionError.GenericTitle",
          };
          yield put(HANDLE_SESSION_ERROR_ACTION(payload));
        }
      }
    } else if (
      errorResponse?.status === 401 &&
      errorResponse?.data?.code &&
      error?.config?.url?.startsWith("/search/resources")
    ) {
      // when search returns 401 without any error code/key, then handle as invalid session error
      console.log("errorResponse", errorResponse)

      const payload = {
        errorKey: ERRORS.COOKIE_EXPIRED_ERROR_KEY,
        errorParameters: "",
        errorMessage: "CWXFR0223E: The user activity cookie is expired.",
        errorCode: ERRORS.COOKIE_EXPIRED_ERROR_CODE,
        handled: false,
        errorTitleKey: "SESSION_TIMEOUT",
        errorMsgKey: "SessionError.TimeoutMsg",
        showKeepLogin: false,
      };
      yield put(HANDLE_SESSION_ERROR_ACTION(payload));

      if(mySite?.isB2B){
        yield put({
          type: ACTIONSHDMDATA.FETCH_HDM_DATA_SUCCESS,
          payload: {
            option: "HDM_PRO_EXPIRED_SESSION",
            response: true
          }
        });
      }

    } else {
      const payload = {
        errorMessage: error.toLocaleString(),
        handled: false,
      };
      yield put(HANDLE_SESSION_ERROR_ACTION(payload));
    }
    resetHandlingError()
  }
}

export function* handleCMCSessionError() {
  const payload = {
    errorKey: ERRORS.CMC_SESSION_ERROR_KEY,
    handled: false,
    errorTitleKey: "SessionError.InvalidTitle",
    errorMsgKey: "SessionError.InvalidMsg",
  };
  yield put(HANDLE_SESSION_ERROR_ACTION(payload));
}

export function* resetError() {
  const sessionErrorObject = yield select(sessionErrorSelector);
  if (!sessionErrorObject.errorKey && !sessionErrorObject.errorCode) {
    //do not reset session error
    const sessionError = { ...defaultStates.error };
    yield put(RESET_ERROR_SUCCESS_ACTION(sessionError));
  } else {
    yield put(RESET_SESSION_POPUP_LOGON_ERROR_ACTION());
  }
}

export function* resetSessionError() {
  const sessionErrorObject = yield select(sessionErrorSelector);
  if (sessionErrorObject.errorKey || sessionErrorObject.errorCode) {
    //reset session error
    const sessionError = { ...defaultStates.error };
    yield put(RESET_ERROR_SUCCESS_ACTION(sessionError));
  }
}

export function* handleValidationError(action: any) {
  const payload = action.payload;
  yield put(VALIDATION_ERROR_ACTION(payload));
}
