
// UI
import { Box, useMediaQuery } from "@material-ui/core"
import { StyledGrid, StyledTypography } from "../../../hdm"
import { SccProductViewContainer } from "./SccProductViewStyles"

// Icons
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as PaidIcon } from "../../../assets/homedepot/images/paid.svg";
import { SccPriceSelector } from "./SccPriceSelector";

import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { cartSelector } from "../../../redux/selectors/order";
import { getSCCPriceList } from "../../../hdm/services/getSccPriceLists.service";
import { useSite } from "../../../_foundation/hooks/useSite";
import theme from "../../../hdm/themes/thd";
import { hdmProductSCC } from "../../../redux/selectors/hdmData";




interface ISccProductView {
    productDefaultPrice: string;
    product: any;
    cssItem: any
    handleChangeSCCselector: (value: any) => void;
}

export const SccProductView = ({productDefaultPrice, product, cssItem, handleChangeSCCselector}:ISccProductView) => {
const { t } = useTranslation();
const [selectedValue, setSelectedValue] = useState("");
const [priceValues, setPriceValues] = useState<any>([]);

const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
const { mySite } = useSite();
const order = useSelector(cartSelector);
const productHasSCC = useSelector(hdmProductSCC);
const {orderId} = order;

return (
    <SccProductViewContainer container  justifyContent="center" alignItems="center" >

        <StyledGrid item xs={isMobile ? 12 : 6}>
            <Box className="prompt-container">
                <SvgIcon className="paid-icon">
                    <PaidIcon/>
                </SvgIcon>
                <StyledTypography className="prompt-text" variant="bodySubText">
                    {t("ProSccProducts.PromptTitle")}
                </StyledTypography>
            </Box>
        </StyledGrid>

        <StyledGrid className="select-container" item xs={isMobile ? 12 : 6}>
            <Box>
                <SccPriceSelector
                    labelText={t("ProSccProducts.SelectLabel")}
                    priceValues={priceValues}
                    setSelectedValue={setSelectedValue}
                    setPriceValues={setPriceValues}
                    selectedValue={selectedValue}
                    productDefaultPrice={productDefaultPrice}
                    sccPrices={cssItem}
                    handleChangeSCCselector={handleChangeSCCselector}
                    isSCCProductConvenio={product.xitem_savedSCCId ?? null}
                    orderHasSCCSaved={productHasSCC.hasSCCSaved ?? false}
                />
            </Box>
        </StyledGrid>

    </SccProductViewContainer>
  )
}