//Return the pageName to be used on BTT events based on Regex
export const getPageName = (pathname, urlMappings) =>{

  let matchedPage:any =null;
  if(pathname === "/pro/" || pathname === "" || pathname === "/"){
    return "HomePage"
  }
  try{
    for(const mappingPart of urlMappings){
      const tmpJson = JSON.parse(mappingPart);
      matchedPage = tmpJson?.urls.find(({url}) => new RegExp(url).test(pathname));
      if(matchedPage) break;
    }
  }catch(e){
    console.log(`urlMapping wrong structure, please refresh with proper values`);
  }
  
  return matchedPage?.pageName ? matchedPage?.pageName : "Otherpage";
}

//This function will handle in localStorage if it's Virtual Trun
export const saveVTFlag = (prevUrl) => {
  sessionStorage.setItem("VTEnabled", prevUrl);
};

export const verifyVT = ( storeConfData ) => {
  const pageNameValue = getPageName(window.location.pathname, retriveStoreConfMaping(storeConfData));
  const VTEnable = sessionStorage.getItem("VTEnabled");
  if (VTEnable) {
    return `${pageNameValue}-VT`;
  } else {
    return pageNameValue;
  }
};

const retriveStoreConfMaping = (storeConfData) =>{
  let flagMapping = true;
  let counterData = 1;
  const mappingValues:any=[];
  while(flagMapping){
    const BTTRegex  = `BTT_REGEX_MAPPING_PT${counterData}`;
    if( storeConfData[BTTRegex]){
      mappingValues.push(storeConfData[BTTRegex]);
      counterData++;
    }else{
      flagMapping=false;
    }
  }
  return mappingValues;
}