/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useState, Dispatch } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useTranslation } from "react-i18next";
//Custom libraries
import MiniCartPopperContent from "./MiniCartPopperContent";
//Redux
import { numItemsSelector } from "../../redux/selectors/order";
//UI
import {
  // StyledAccountPopper,
  StyledButton,
  StyledTypography,
  StyledHeaderActions,
  StyledGrid,
  // StyledGrid,
} from "../../hdm";
// import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
// import { useWinDimsInEM } from "../../_foundation/hooks/use-win-dims-in-em";
// import { S_MOBILE_W, XS_MOBILE_W } from "../../constants/common";
import Cart from "../../assets/homedepot/images/quickcart-icon.svg";
import styled from "styled-components";
// import { palette } from "../../hdm/themes";
import { palette } from "../../hdm/themes";
import StyledCartDrawer from "../custom-components/styled-cart-drawer/StyledCartDrawer";
import { OPEN_CART_DRAWER_ACTION } from "../../redux/actions/drawer";
// import { useCart } from "../../_foundation/hooks/use-cart";
import { useSite } from "../../_foundation/hooks/useSite";

interface MiniCartProps {
  id: string;
  open: boolean;
  handleClick: (e?: any) => void;
  handleClose: (e?: any) => void;
}

/**
 * MiniCart component
 * displays number of cart items icon
 * @param props
 */

export const StyledButtonHDM = styled((props: any) => <StyledButton {...props} />)`
  ${({ theme, isB2B }) => `
      background-color: transparent!important;
      & .MuiTouchRipple-root{
        width: 54px;
        height: 40px;
      }
      ${theme.breakpoints.down("sm")}{
        ${
          isB2B
            ? `
        `
            : `
        margin-top: ${theme.spacing(2)}px;
        `
        }
      }
      & .MuiButton-label{
            line-height: normal;
            display: flex;
            & .MuiTypography-root {
                border-radius: 3px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: black;
                margin-left: 5px;
                // margin-top:5px;
                // padding: ${theme.spacing(1)}px;
                background:${palette.gray["50"]};
            }

            ${
              isB2B
                ? `
            width: 74px;
            height: 40px;
            `
                : ``
            }
            
            ${theme.breakpoints.down("sm")}{
              ${
                isB2B
                  ? `
              // width: 54px;
              `
                  : ``
              }


            }


            ${theme.breakpoints.up(1024)}{
            line-height: normal;
            padding-top: ${theme.spacing(1)}px;
          }
      }

      .styled--minicarticon-products {
        // margin-top: -5px;
        min-width: 10px;
      }

      .minicart-container-pro {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 74px;
        height 40px;
        padding: 0px;
        
        ${theme.breakpoints.down("sm")}{
          // width: 54px;

        }
      }

      &.MuiButtonBase-root {
      color: inherit;
      border: 0;
      cursor: pointer;
      margin: 0;
      display: inline-flex;
      outline: 0;
      padding: 0;
      position: relative;
      align-items: center;
      user-select: none;
      border-radius: 0;
      vertical-align: middle;
      -moz-appearance: none;
      justify-content: center;
      text-decoration: none;
      background-color: transparent;
      -webkit-appearance: none;
      -webkit-tap-highlight-color: transparent;
}

      
  `}
`;

const MiniCart = React.forwardRef<HTMLButtonElement | null, MiniCartProps>((props: any, ref: any) => {
  // const { id, open, handleClick, handleClose } = props; // original props
  // const small = S_MOBILE_W;
  // const offset = w <= xSmall ? { offset: "-48" } : undefined;
  // const { w } = useWinDimsInEM();
  // const xSmall = XS_MOBILE_W;
  /* original props */
  const { mySite } = useSite();
  const isB2B = Boolean(mySite?.isB2B);
  const { handleClose } = props;
  // const { t } = useTranslation();
  const numItems = useSelector(numItemsSelector);
  // const [arrowRef, setArrowRef] = useState<any>(null);
  const dispatch = useDispatch<Dispatch<any>>();
  const [showCart, setShowCart] = useState(false);

  // const cartInfo = useCart();

  return (
    <>
      {isB2B ? (
        <StyledButtonHDM
          testId="header-mini-cart-button"
          ref={ref}
          variant="text"
          color="secondary"
          isB2B={isB2B}
          onClick={() => dispatch(OPEN_CART_DRAWER_ACTION({source: "miniCart"}))}>
          <StyledHeaderActions className="minicart-container-pro">
            <img src={Cart} alt="carrito compras" width={20} height={17} className="bottom-margin-1 lazyload" />
            <StyledTypography variant="bodyCaption" className="styled--minicarticon-products">
              {numItems}
            </StyledTypography>
          </StyledHeaderActions>
        </StyledButtonHDM>
      ) : (
        <StyledButtonHDM
          testId="header-mini-cart-button"
          ref={ref}
          className="header-actionsButton"
          variant="text"
          color="secondary"
          // onClick={() => setShowCart(true)}>
          isB2B={isB2B}
          // onClick={() => dispatch(OPEN_CART_DRAWER_ACTION(() => console.log("open")))}
          onClick={() => dispatch(OPEN_CART_DRAWER_ACTION({source: "miniCart"}))}
          >
          <StyledHeaderActions>
            <img
              src={Cart}
              alt="carrito compras"
              width={20}
              height={17}
              className={isB2B ? "bottom-margin-1 lazyload" : ""}
            />
            <StyledTypography variant="bodyCaption" className={isB2B ? "styled--minicarticon-products" : ""}>
              {numItems}
            </StyledTypography>
          </StyledHeaderActions>
        </StyledButtonHDM>
      )}

      {/* <StyledCartDrawer showCart={showCart} setShowCart={setShowCart}> */}

      <StyledCartDrawer>
        <MiniCartPopperContent handleClose={handleClose} setShowCart={setShowCart} showCart={showCart} />
      </StyledCartDrawer>

      {/* <StyledSwipeableDrawerHDM variant="cart" isOpen={showCart} setOpenDrawer={setShowCart}>
        <MiniCartPopperContent handleClose={handleClose} setShowCart={setShowCart} showCart={showCart} />
      </StyledSwipeableDrawerHDM> */}
      {/* </StyledCartDrawer> */}

      {/* <StyledAccountPopper
        id={id}
        open={open}
        anchorEl={ref?.current}
        onClose={handleClose}
        placement={w <= xSmall ? "bottom-start" : w <= small ? "bottom" : "bottom-end"}
        modifiers={{
          offset,
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: false,
            boundariesElement: "scrollParent",
          },
          hide: {
            enabled: false,
          },
          arrow: {
            enabled: true,
            element: arrowRef,
          },
        }}
        className="mini-cart-popper">
        <span className="arrow" ref={setArrowRef} />
        <MiniCartPopperContent handleClose={handleClose} />
      </StyledAccountPopper> */}
    </>
  );
});

export default MiniCart;
