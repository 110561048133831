import { createReducer, AnyAction } from "@reduxjs/toolkit";
import { NOT_FOUND } from "http-status-codes";

import * as ACTIONS from "../action-types/hdmData";
import initStates from "./initStates";
import { hdmDataReducerState } from "./reducerStateInterface";

const hdmDataReducer = createReducer(initStates.hdmData, (builder) => {
  builder.addCase(ACTIONS.FETCH_HDM_DATA_REQUESTED, (state: hdmDataReducerState, action: AnyAction) => {
    state.isFetching = true;
  });

  builder.addCase(ACTIONS.FETCH_HDM_DATA_SUCCESS, (state: hdmDataReducerState, action: AnyAction) => {
    const payload = action.payload;
    
    if (payload) {
      if (payload.option === "ONLINE_STORE") {
        state.exclusive = payload.response?.exclusive;
        state.onlineStore = payload.response?.onlineStore;
        state.paymentSequence = payload.response?.onlineStore?.paymentSequence;
      }

      if (payload.option === "ESTADOS_CIUDADES") {
        state.estadosCiudades = payload.response;
      }

      if (payload.option === "PDP_QUANTITY") {
        state.pdpQuantity = payload.response;
      }

      if (payload.option === "CHECKOUT_STEP") {
        state.checkOutStep = payload.response;
      }

      if (payload.option === "PDP_SEO") {
        state.pdpSeo = payload.response;
      }

      if (payload.option === "CUENTA_MIS_ORDENES") {
        state.cuentaMisOrdenesFiltros = payload.response;
      }

      if (payload.option === "GUEST_USER_DATA") {
        state.guestUserData = payload.response;
      }

      if (payload.option === "HDM_STORES_DATA") {
        state.hdmStoresData = payload.response;
      }

      if (payload.option === "DELIVERY_METHOD") {
        state.deliveryMethodId = payload.response;
      }

      if (payload.option === "WISH_LIST_SELECTED_PRODUCTS") {
        state.wishListSelectedProduct = payload.response;
      }

      if (payload.option === "WISH_LIST_PRODUCTS_ORDERBY") {
        state.wishListProductsOrderBy = payload.response;
      }

      if (payload.option === "WISH_LIST_SEARCH_PRODUCTS") {
        state.wishListSearchProduct = payload.response;
      }

      if (payload.option === "WISH_LIST_PRODUCTS_QUANTITY") {
        state.wishListProductsQuantity = payload.response;
      }

      if (payload.option === "All_LINEA_GAMA_COLORS") {
        state.lineaGamaColors = payload.response.allLineaGamasColors;
        state.hdmColors = payload.response.hdmColors;
      }
      if (payload.option === "HDM_DRAWER_COLOR_DATA") {
        state.hdmDrawerColorData = payload.response;
      }
      if (payload.option === "PDP_PRODUCT_DATA") {
        state.hdmPDPproductData = payload.response;
      }
      if (payload.option === "BOPIS_DRAWER_CART") {
        state.bopisDrawerCart = payload.response;
      }
      if (payload.option === "ORDER_RESUME_OPT_DATA") {
        state.orderResumeOptData = payload.response;
      }
      if (payload.option === "EMARSYS_SEARCH_TAG_DATA") {
        state.emarsysSearchTagData = payload.response;
      }
      if (payload.option === "EMARSYS_CATEGORY_TAG_DATA") {
        state.emarsysCategoryTagData = payload.response;
      }
      if (payload.option === "EMARSYS_PDP_TAG_DATA") {
        state.emarsysPDPTagData = payload.response;
      }
      if (payload.option === "EMARSYS_TYP_TAG_DATA") {
        state.emarsysTYPTagData = payload.response;
      }
      if (payload.option === "EMARSYS_PDP_TAG_FINISHED") {
        state.emarsysPDPTagFinished = payload.response;
      }
      if (payload.option === "EMARSYS_WIDGETS_TYPES") {
        state.emarsysWidgetType = payload.response;
      }
      if (payload.option === "EMARSYSCARRUSEL_ADDITIONAL_READY") {
        state.emarsysAdditionalReady = payload.response;
      }
      if (payload.option === "EMARSYSCARRUSEL_REGULAR_READY") {
        state.emarsysRegularReady = payload.response;
      }
      if (payload.option === "PDPSTORE_BUYABLE_EXCLUDE") {
        state.storeBuyableExclude = payload.response;
      }
      if (payload.option === "LOAD_GALLERY") {
        state.isGalleryLoaded = payload.response;
      }
      if (payload.option === "LOADER_CHECKOUT_STEP") {
        state.loaderCheckoutStep = payload.response;
      }
      if (payload.option === "FOOTER_ROUTE") {
        state.footerRoute = payload.response;
      }
      if (payload.option === "EMARSYSBUNDLE_ITEMS_READY") {
        state.hdmEmarsysBundleItemsReady = payload.response;
      }
      if (payload.option === "CHECKOUT_PROFILE_SEARCH_DATA") {
        state.hdmCheckoutProfileSearchData = payload.response;
      }
      if (payload.option === "CART_PRODUCTS_HAS_SCC") {
        state.hdmProductSCC = payload.response;
      }
      if (payload.option === "HDM_PRO_EXPIRED_SESSION") {
        state.hdmProExpiredSession = payload.response;
      }
    }
  });

  builder.addCase(ACTIONS.FETCH_HDM_DATA_ERROR, (state: hdmDataReducerState, action: AnyAction) => {
    state.isFetching = false;
  });

  builder.addCase(ACTIONS.SET_HDM_INSTALLATION_DATA, (state: hdmDataReducerState, action: AnyAction) => {
    return {
      ...state,
      installationPartNumber: action.payload.installationPartNumber,
      installationPrice: action.payload.installationPrice,
    };
  });
});

export default hdmDataReducer;
