/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { call, put, select } from "redux-saga/effects";
//Foundation libraries
import { PERSONALIZATION_ID } from "../../../_foundation/constants/user";
import { EXPIRED_PASSWORD_PAGE_ERROR, WC_PREVIEW_TOKEN } from "../../../_foundation/constants/common";
import loginIdentity from "../../../_foundation/apis/transaction/loginIdentity.service";
import { localStorageUtil, storageSessionHandler } from "../../../_foundation/utils/storageUtil";
import personService from "../../../_foundation/apis/transaction/person.service";
import userContextService from "../../../_foundation/apis/transaction/userContext.service";
import wishListService from "../../../_foundation/apis/transaction/wishList.service";

import Axios, { Canceler } from "axios";
//Redux
import * as ACTIONS from "../../action-types/user";
import {
  REGISTRATION_SUCCESS_ACTION,
  LOGOUT_SUCCESS_ACTION,
  LOGIN_SUCCESS_ACTION,
  INIT_USER_FROM_STORAGE_SUCCESS_ACTION,
  FETCH_USER_DETAILS_SUCCESS_ACTION,
  SESSION_ERROR_LOGIN_ERROR_ACTION,
  GUEST_LOGIN_SUCCESS_ACTION,
  LOGON_AND_CHANGE_PASSWORD_FAIL_ACTION,
  loginErrorAction,
} from "../../actions/user";
import { userLastUpdatedSelector } from "../../selectors/user";
import { USER_CONTEXT_REQUEST_SUCCESS_ACTION } from "../../actions/context";
import { ENTITLED_ORG_ACTION, GET_ORGANIZATION_ADDRESS_ERROR_ACTION, GET_ORGANIZATION_ADDRESS_SUCCESS_ACTION } from "../../actions/organization";
import { FETCH_CONTRACT_REQUESTED_ACTION } from "../../actions/contract";
import { GET_USER_WISHLIST_ACTION } from "../../actions/wish-list";
import { setCookie } from "../../../components/custom-components/styled-store-selector/cookieHandler";
import { siteSelector } from "../../selectors/site";
import { ADDRESS_COUNTRY, IBM_ORG_ENTITY_DETAILS } from "../../../constants/common";
import organizationService from "../../../_foundation/apis/transaction/organization.service";
import { ADDRESS_DETAILS_GET_SUCCESS } from "../../action-types/account";
import axios from "axios";
import { WISH_LIST_FAVORITES } from "../../../hdm/functions/constants";
import personContactService from "../../../_foundation/apis/transaction/personContact.service";
import { logEventWithValues } from "../../../hdm/functions/utils";
import { removeMetaAttributes, validateMetaAttributes } from "../../../components/custom-components/conversion-api/meta/meta-convertions-utils";
import { hdmProExpiredSession, onlineStoreRdc } from "../../selectors/hdmData";
import { zipCodeBlockListService } from "../../../services/zipCodeBlockList.service";
const cancels: Canceler[] = [];
const CancelToken = Axios.CancelToken;

function* loginAndFetchDetail(payload: any) {
  const response = yield call(loginIdentity.login, payload);
  const _user = {
    ...response.data,
    physicalStoreId: payload.physicalStoreId,
  };
  if (payload?.query?.rememberMe) {
    //add rememberMe true to user state
    _user["rememberMe"] = payload.query.rememberMe;
  }

  logEventWithValues("SignIn", _user)
  yield put(LOGIN_SUCCESS_ACTION(_user));

  let response2 = yield call(personService.findPersonBySelf, {
    widget: payload.widget,
  });

  const mySite = yield select(siteSelector);
  if (response2?.data && response2?.data?.firstName && response2?.data?.lastName) {
    setCookie("WC_LogonUserId_" + mySite.storeID, response2?.data?.firstName + ' ' + response2?.data?.lastName, 1);
  } 

  if(mySite.isB2B && response2?.data && response2?.data?.orgizationId){

    localStorageUtil.remove("CheckoutDeliveryContact");

    const isUpdate = yield call(getOrgFetails, {userData: response2})

    if(isUpdate){
      response2 = yield call(personService.findPersonBySelf, {
        ...payload,
      });
    }
  }
  const { contact, email1, profileType } = response2.data;
  // Only for DIY users
  if (contact && profileType === "Consumer") {
    const addressesToRemove = contact?.filter(
      (address) => address?.email1 !== email1 && address?.nickName === address?.email1
    )[0];
    if (addressesToRemove) {
      const addressRes = yield call(personContactService.deletePersonContact, {
        nickName: addressesToRemove?.nickName,
      });
      const primaryAddRes = yield call(personContactService.updatePersonContact, {
        nickName: email1,
        body: {
          primary: "true",
        },
      });
      if (addressRes?.status === 200 && primaryAddRes?.status === 200) {
        response2 = yield call(personService.findPersonBySelf, {
          ...payload,
        });
      }
    }
  }
  const loginPayload2 = response2.data;

  const blockListResponse= mySite.isB2B ? null : yield call(zipCodeBlockListService.getAllZipCodeBlockList);
  if(blockListResponse && loginPayload2?.contact){
     const blockedZipCodesSet = new Set(blockListResponse);
     const updatedContact = loginPayload2.contact.map(contactItem => ({
     ...contactItem,
     zipCodeInBlocklist:blockedZipCodesSet.has(contactItem.zipCode)
     }));
     const updatedPersonData = {
       ...loginPayload2,
       zipCodeInBlocklist:blockedZipCodesSet.has(loginPayload2.zipCode),
       contact: updatedContact,
     };
     yield put(FETCH_USER_DETAILS_SUCCESS_ACTION(loginPayload2));
     yield put({
      type: ADDRESS_DETAILS_GET_SUCCESS,
      response: updatedPersonData
     });
   }else{
    yield put(FETCH_USER_DETAILS_SUCCESS_ACTION(loginPayload2));
    yield put({
      type: ADDRESS_DETAILS_GET_SUCCESS,
      response: loginPayload2,
    });
    }
  


  try{
    const {email1 : em, phone1: ph} = loginPayload2;
    const storeConfData = yield select(onlineStoreRdc); 
    validateMetaAttributes(em,ph,storeConfData,false);
  }catch(e){console.log("Error setting metaAtrr")}
  
}

const preProcessLogonAndChangePasswordError = (error: any) => {
  if (error?.isAxiosError && error.response?.data?.errors && error.response.data.errors[0]) {
    return {
      ...error.response.data.errors[0],
      [EXPIRED_PASSWORD_PAGE_ERROR]: true,
    };
  } else {
    return {
      errorMessage: error.toLocaleString(),
      [EXPIRED_PASSWORD_PAGE_ERROR]: true,
    };
  }
};

export function* logonAndChangePassword(action: any) {
  try {
    const payload = action.payload;
    yield* loginAndFetchDetail(payload);
  } catch (error) {
    yield put(LOGON_AND_CHANGE_PASSWORD_FAIL_ACTION(preProcessLogonAndChangePasswordError(error)));
  }
}

export function* login(action: any) {

  try {
    createTempCookie();
    const payload = action.payload;
    yield* loginAndFetchDetail(payload);
  } catch (error) {
    yield put({ type: loginErrorAction, error });
  }

}

export function* sessionErrorReLogin(action: any) {
  try {
    const payload = action.payload;
    const currentUser = storageSessionHandler.getCurrentUserAndLoadAccount();
    if (currentUser?.rememberMe) {
      payload.query
        ? (payload.query.rememberMe = currentUser.rememberMe)
        : (payload.query = { rememberMe: currentUser.rememberMe });
    }

    storageSessionHandler.removeCurrentUser();
    yield* loginAndFetchDetail(payload);
  } catch (error: any) {
    if (error && error.response && error.response.data && error.response.data.errors && error.response.data.errors[0]) {
      yield put(SESSION_ERROR_LOGIN_ERROR_ACTION(error.response.data.errors[0]));
    }
  }
}

const createTempCookie = () => {
  // create a 1 min expiration cookie
  const second = 1 / 24 / 60 / 60;
  const expiryTime = second * 15;
  setCookie("UTMEXPIRY", 1, expiryTime);
};

export function* logout(action: any) {
  createTempCookie();
  const payload = action.payload;

  try {
    yield call(loginIdentity.logout, payload);
    yield put(LOGOUT_SUCCESS_ACTION(payload));
  } catch (error) {
    yield put({ type: ACTIONS.LOGOUT_ERROR, error });
    //still need to clear user token, event though logout fail to avoid infinite loop
    yield put(LOGOUT_SUCCESS_ACTION(payload));
  }
  const mySite = yield select(siteSelector);
  setCookie("WC_LogonUserId_" + mySite.storeID, "", 0);

  try{
    const storeConfData = yield select(onlineStoreRdc); 
    removeMetaAttributes(storeConfData);
  }catch(e){console.log("Error removing metaAtrr")}
  
  const proExpiredSession = yield select(hdmProExpiredSession)
  if(mySite.isB2B && proExpiredSession){
    console.log("proExpiredSessionQQ", proExpiredSession)
    setTimeout(() => {
        window.location.reload();        
    }, 500);
  }
}

const handleCreateWish = () => {
  const widget = "useWishList";
  const params = {
    body: {
      description: WISH_LIST_FAVORITES,
      registry: false,
    },
    widget,
    cancelToken: new CancelToken((c) => cancels.push(c)),
  };
  try {
    wishListService.createWishlist(params);
  } catch (error) {
    console.log("Error in creating wish list", error);
  }
};

export function* registration(action: any) {
  try {
    const payload = action.payload;
    const response = yield call(personService.registerPerson, payload);

    const registrationPayload = response.data;
    if (payload?.query?.rememberMe) {
      registrationPayload["rememberMe"] = payload.query.rememberMe;
    }
    handleCreateWish();
    yield put(REGISTRATION_SUCCESS_ACTION(registrationPayload));

    const response2 = yield call(personService.findPersonBySelf, {
      widget: payload.widget,
    });
    const registrationPayload2 = response2.data;
    yield put(FETCH_USER_DETAILS_SUCCESS_ACTION(registrationPayload2));
    try{
      const {email1 : em, phone1: ph} = registrationPayload2;
      const storeConfData = yield select(onlineStoreRdc);
      validateMetaAttributes(em,ph,storeConfData,false);
    }catch(e){console.log("Error setting metaAtrr")}

    const mySite = yield select(siteSelector);
    if (response2?.data && response2?.data?.firstName && response2?.data?.lastName) {
      setCookie("WC_LogonUserId_" + mySite.storeID, response2?.data?.firstName + ' ' + response2?.data?.lastName, 1);
    }

    yield put(
      GET_USER_WISHLIST_ACTION({
        widget: "useWishList",
        cancelToken: new CancelToken((c) => cancels.push(c)),
      })
    );
  } catch (error) {
    yield put({ type: ACTIONS.REGISTRATION_ERROR, error });
  }
}

export const getOrgContactInfo = async ({userData, mySite}) => {

  const url = `/wcs/resources/store/${mySite.storeID}/person/csr/details/${userData?.data?.userId}?profileName=IBM_User_Registration_Details`
  return await axios.get(url)
  .then(response => {

      return {
        departamento: response.data.userProfile.userProfileField1,
        puesto: response.data.userProfile.userProfileField2,
        idType: response.data.demographics.field6,
        idNumber: response.data.demographics.field7
      }

  })
  .catch(error => {
    console.log('Could not retrieve profile details', error)
    return {
        departamento: "",
        puesto: "",
        tipiId: "",
        numId: ""
      }
  })

}

export function* getOrgFetails({userData}) {
  let isUpdate = false
  const mySite = yield select(siteSelector);
  try {

    const responseOrg = yield call(organizationService.findByOrganizationId, {organizationId: userData?.data?.orgizationId, profileName: IBM_ORG_ENTITY_DETAILS});
    const responseOrg2 = yield call(getOrgContactInfo, {userData, mySite});
    responseOrg.data.orgContactInfo = responseOrg2
    if(userData.data?.city && userData.data?.zipCode){
  
      const [calle, numExt, colony] = userData.data?.addressLine
      const city = userData.data?.city
      const state = userData.data?.state
      const zipCode = userData.data?.zipCode
      
      const calleOrg = responseOrg?.data?.orgProperties?.address1
      const numExtOrg = responseOrg?.data?.orgProperties?.address2
      const colonyOrg = responseOrg?.data?.orgProperties?.address3
      const cityOrg = responseOrg?.data?.orgProperties?.city
      const stateOrg = responseOrg?.data?.orgProperties?.state
      const zipCodeOrg = responseOrg?.data?.orgProperties?.zipCode
  
      let updateAddress = false
      if(calle.trim() !== calleOrg.trim()){
        updateAddress = true
      }
      if(numExt.trim() !== numExtOrg.trim()){
        updateAddress = true
      }
      if(colony.trim() !== colonyOrg.trim()){
        updateAddress = true
      }
      if(city.trim() !== cityOrg.trim()){
        updateAddress = true
      }
      if(state.trim() !== stateOrg.trim()){
        updateAddress = true
      }
      if(zipCode.trim() !== zipCodeOrg.trim()){
        updateAddress = true
      }
  
      if(updateAddress){
        const requestParams = {
          action: "updateUserRegistration",
          body: {
            address1: calleOrg.trim(),
            address2: numExtOrg.trim(),
            address3: colonyOrg.trim(),
            zipCode: zipCodeOrg.trim(),
            addressField3: "",
            city: cityOrg.trim(),
            state: stateOrg.trim(),
            country: ADDRESS_COUNTRY,
            editRegistration: "Y",
            addressType: "SB",
          },
        };
  
        const updateOrg = yield call(personService.updatePerson, requestParams);
        
        isUpdate = true
      }
  
    }else {
  
      const requestParams = {
        action: "updateUserRegistration",
        body: {
          address1: responseOrg?.data?.orgProperties?.address1.trim(),
          address2: responseOrg?.data?.orgProperties?.address2.trim(),
          address3: responseOrg?.data?.orgProperties?.address3.trim(),
          zipCode: responseOrg?.data?.orgProperties?.zipCode.trim(),
          addressField3: "",
          city: responseOrg?.data?.orgProperties?.city.trim(),
          state: responseOrg?.data?.orgProperties?.state.trim(),
          country: ADDRESS_COUNTRY,
          editRegistration: "Y",
          addressType: "SB",
        },
      }
  
      const updateOrg = yield call(personService.updatePerson, requestParams);
      isUpdate = true
      
    }

    yield put(GET_ORGANIZATION_ADDRESS_SUCCESS_ACTION(responseOrg.data));
  } catch (error) {
    yield put(GET_ORGANIZATION_ADDRESS_ERROR_ACTION(error));
  }

  return isUpdate
}

export function* initStateFromStorage(action: any) {
  try {
    let currentUser = storageSessionHandler.getCurrentUserAndLoadAccount();
    if (currentUser === null) {
      //
      // if we have both previewtoken and newPreviewSession, the current user is removed in inistates.ts
      // then we should get new personalizationID from preview session
      const previewToken = storageSessionHandler.getPreviewToken();
      if (!previewToken || !previewToken[WC_PREVIEW_TOKEN]) {
        const personalizationID = localStorageUtil.get(PERSONALIZATION_ID);
        if (personalizationID !== null) {
          currentUser = { personalizationID };
        }
      }
    }

    yield put(INIT_USER_FROM_STORAGE_SUCCESS_ACTION(currentUser));
    if (currentUser && currentUser.WCToken) {
      let response2 = yield call(personService.findPersonBySelf, {
        ...action.payload,
      });

      const mySite = yield select(siteSelector);
      if(mySite.isB2B && response2?.data && response2?.data?.orgizationId){

        const isUpdate = yield call(getOrgFetails, {userData: response2})

        if(isUpdate){
          response2 = yield call(personService.findPersonBySelf, {
            ...action.payload,
          });
        }
      }

      const loginPayload2 = response2.data;
      const blockListResponse= mySite.isB2B ? null : yield call(zipCodeBlockListService.getAllZipCodeBlockList);
      if(blockListResponse && loginPayload2?.contact){
        const blockedZipCodesSet = new Set(blockListResponse);
        const updatedContact = loginPayload2.contact.map(contactItem => ({
        ...contactItem,
        zipCodeInBlocklist:blockedZipCodesSet.has(contactItem.zipCode)
        }));
        const updatedPersonData = {
          ...loginPayload2,
          zipCodeInBlocklist:blockedZipCodesSet.has(loginPayload2.zipCode),
          contact: updatedContact,
        };
        yield put(FETCH_USER_DETAILS_SUCCESS_ACTION(loginPayload2));
        yield put({
          type: ADDRESS_DETAILS_GET_SUCCESS,
          response: updatedPersonData
        });
      }else{
        yield put(FETCH_USER_DETAILS_SUCCESS_ACTION(loginPayload2));
        yield put({
          type: ADDRESS_DETAILS_GET_SUCCESS,
          response: loginPayload2,
        });
      }
   
    }

    const response3 = yield call(userContextService.getContextData, { ...action.payload });
    const userPayload = response3.data;
    
    yield put(USER_CONTEXT_REQUEST_SUCCESS_ACTION({ ...userPayload }));
    yield put(ENTITLED_ORG_ACTION({ ...action.payload }));
    yield put(FETCH_CONTRACT_REQUESTED_ACTION({ ...action.payload, userContext: userPayload }));
  } catch (e) {
    console.warn(e);
  }
}

export function* updateStateFromStorage(action: any) {
  try {
    const currentUser = storageSessionHandler.getCurrentUserAndLoadAccount();
    if (currentUser && currentUser.forUserId) {
      return;
    }
    const userLastUpdated = yield select(userLastUpdatedSelector);
    if (currentUser && currentUser.lastUpdated && (!userLastUpdated || userLastUpdated < currentUser.lastUpdated)) {
      yield put(INIT_USER_FROM_STORAGE_SUCCESS_ACTION(currentUser));
      if (currentUser.isGuest) {
        yield put(GUEST_LOGIN_SUCCESS_ACTION(null));
      } else {
        yield put(LOGIN_SUCCESS_ACTION(null));
      }
    }
  } catch (e) {
    console.warn(e);
  }
}
