import { SkipPrevious } from "@material-ui/icons";
import { useEffect, useRef, useState } from "react";
import { backdropLoaderSelector } from "../../../redux/selectors/backdrop-loader";
import { useSelector } from "react-redux";
import { getDepartmentGTMElements, setupDivs, updatePromotionDetails } from "../Datalayer-Utils/dataLayer-func";
import { onlineStoreRdc } from "../../../redux/selectors/hdmData";
import { verifyVT } from "../blue-triangle-hdm/blue-triangle-functions";
import { logEventWithValues } from "../../../hdm/functions/utils";
import { triggerPageView } from "../conversion-api/meta/meta-convertions-utils";
import { useSite } from "../../../_foundation/hooks/useSite";
import { sessionErrorSelector } from "../../../redux/selectors/error";
import { breadcrumbsSelector } from "../../../redux/selectors/catalog";
import { triggerConversionsPinterestViewCategory } from "../conversion-api/pinterest/pinterest-conversion-utils";
import { loginStatusSelector } from "../../../redux/selectors/user";

export const GtmLoaded = (props) => {
  const { isGTMLoaded, setIsGTMLoaded, isGTMPDP, partNumberBT, categoryBT, categoryBT2, categoryBT3 } = props;
  const [observedElements, setObservedElements] = useState<any[]>([]);
  const [gtmElements, setGtmElements] = useState<any>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const pageviewTriggered = useRef(false);
  const pageLoadTriggered = useRef(false);
  const userLoggedIn = useSelector(loginStatusSelector);
  const prevUserLogStatus = useRef(false);

  const prevURL = useRef("");

  const sessionError = useSelector(sessionErrorSelector);
  const { mySite } = useSite();
  const storeConfData = useSelector(onlineStoreRdc);
  const isBTEnabled = storeConfData?.BT_ACTIVE_FLAG;
  const btEnvironment = storeConfData?.BT_ENVIRONMENT;

  const loaderOpenState = useSelector(backdropLoaderSelector);
  const category = useSelector(breadcrumbsSelector);
  const [prevLoaderState, setPrevLoaderState] = useState<boolean>();
  const isMobileApp = window.navigator.userAgent.includes("THDMXAPP");

  let loaded = false;
  let attempts = 0;
  let attemptDiv = 0;
  let loaderChanged = false;
  const promoDetails = new Map();

  //Logic to force logic execution when loggin success.
  useEffect(() => {
    if (userLoggedIn && prevUserLogStatus.current != userLoggedIn) {
      pageLoadTriggered.current = true;
      prevUserLogStatus.current = userLoggedIn;
      triggerPageViewLogic();
      pageviewTriggered.current = true;
      window["dataLayer"].push({
        event: "page_load",
        ecommerce: {},
      });

      const page_load = new CustomEvent("page_load", {
        detail: {},
        bubbles: true,
        cancelable: true,
        composed: false,
      });

      dispatchEvent(page_load);
    }
  }, [userLoggedIn]);

  //Trigger based on BackLoaderOpenState
  useEffect(() => {
    if (loaderOpenState === false && prevLoaderState === true) {
      sendGtmEvent(true);
    }
    setPrevLoaderState(loaderOpenState);
    loaderChanged = true;
  }, [loaderOpenState]);

  //Reset flags when url change
  useEffect(() => {
    //Reset flag to use when user change the page
    loaded = false;
    attempts = 0;
    loaderChanged = false;
    sendGtmEvent(false);
    pageviewTriggered.current = false;
    if(prevURL.current !== window.location.pathname){
      pageLoadTriggered.current = false;
    }
  }, [window.location.pathname]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.4,
    };
    const viewPromotion = (entries) => {
      let promotionId = "",
        promotionName = "";
      let isDataLoc = true;
      let creativeName = document.querySelector(".data-location")?.textContent;
      let creativeSlot = 0;
      const items: any = [];

      setupDivs();

      if (creativeName == null || creativeName == undefined) {
        isDataLoc = false;
      }
      entries.forEach((entry, index) => {
        if (entry.isIntersecting && !observedElements.includes(entry.target)) {
          setObservedElements((prevElements) => [...prevElements, entry.target]);
          promotionId = entry.target.dataset.div; //Cambiar por id

          const subElements = entry.target.querySelectorAll(".gtm_class");
          Array.from(subElements).forEach((elemento: any, index) => {
            const imgs = elemento.querySelector("img");
            if (imgs && imgs instanceof HTMLImageElement) {
              const prom= imgs.getAttribute("promotion-name");
              if(prom != null){
                promotionName = prom;
              }else{
                promotionName = imgs.alt;
                const dtl = elemento.querySelector(`img[alt="${promotionName}"]`);
                promotionName = dtl!=null? dtl : promotionName;
              }
            } else {
              if(elemento instanceof HTMLElement){
                const prom= elemento.getAttribute("promotion-name");
                if(prom != null){
                  promotionName = prom;
                }else{
                  promotionName = elemento.textContent !=null ? elemento.textContent
                  .trim()
                  .replace(/ {2,}/g, " - ")
                  .replace("chevron_right", ""):"" ;
                  promotionName = promotionName.replace(/-(.*)-/g, "- $1").replace(/\n/g, "");
                }
              }
            }
            creativeSlot = index + 1;
            if (isDataLoc == false) {
              creativeName = getDepartmentGTMElements(window.location.pathname);
            }

            const promotion = {
              creative_name: creativeName,
              creative_slot: creativeSlot,
              promotion_id: promotionId,
              promotion_name: promotionName,
              promo_thd: "NA",
            };
            items.push(promotion);
            promoDetails.set(promotionName, promotion);
            elemento.addEventListener("click", function handleClick(this) {
              console.log(`DL_SelectPromtion`);
              if (this.getAttribute("promotion-name") && promoDetails.get(this.getAttribute("promotion-name")) !== null) {
                const selectedProm = promoDetails.get(this.getAttribute("promotion-name"));
                const keys = this.getAttribute("href").split("/");
                const keyWords = keys.filter((palabra) => palabra != "");

                const promoDtl = {
                  list_partnumbers: [],
                  key_words: keyWords,
                  promotion_name: selectedProm.promotion_name,
                  promotion_id: selectedProm.promotion_id,
                  creative_name: selectedProm.creative_name,
                  creative_slot: selectedProm.creative_slot,
                };
                updatePromotionDetails(promoDtl);
                window["dataLayer"].push({ ecommerce: null }); // Clear the previous ecommerce object.
                const promotion = promoDetails.get(this.getAttribute("promotion-name"))
                if(isMobileApp){
                  try {
                    logEventWithValues("select_promotion",
                      {
                        promotion
                      }
                    )
                  } catch (e) {
                    console.log(e);
                  }
                  
                }
                else {
                window["dataLayer"].push({
                  event: "select_promotion",
                  ecommerce: promoDetails.get(this.getAttribute("promotion-name")),
                });
              }
            }
            });
          });
        }
      });

      if (items.length > 0) {
        console.log(`DL_VP`);
        window["dataLayer"].push({ ecommerce: null }); // Clear the previous ecommerce object.
        if(isMobileApp){ 
          try {
            logEventWithValues("view_promotion",
              {
                items
              }
            )
          } catch (e) {
            console.log(e);
          }
          
        }
        else {
        window["dataLayer"].push({
          event: "view_promotion",
          ecommerce: {
            items: items,
          },
        });
      }
      }
    };

    const observer = new IntersectionObserver(viewPromotion, options);
    const elementosConClase = document.querySelectorAll(`[data-div]`);
    if (gtmElements) {
      gtmElements.forEach((elemento) => {
        observer.observe(elemento);
      });
    }

    //clear the component
    return () => {
      if (gtmElements) {
        gtmElements.forEach((elemento) => {
          observer.unobserve(elemento);
        });
      }
    };
  }, [observedElements]);

  useEffect(() => {
    setObservedElements([""]);
  }, [gtmElements]);

  const triggerGTMPageLoad = () => {
    if (!pageLoadTriggered.current) {
      triggerPageViewLogic();
      dispatchPageLoad();
      setIsLoaded(true);
      prepareViewPromotion(false);
    }
  };

  const dispatchPageLoad = () => {
    if (!pageLoadTriggered.current) {
      window["dataLayer"].push({
        event: "page_load",
        ecommerce: {},
      });

      const page_load = new CustomEvent("page_load", {
        detail: {},
        bubbles: true,
        cancelable: true,
        composed: false,
      });

      dispatchEvent(page_load);
      pageLoadTriggered.current = true;
    }
  };

  const endBTTEvent = () => {
    if (isBTEnabled) {
      const pageNameVal = verifyVT(storeConfData);

      try {
        window["bttUT"].end({
          pageName: pageNameVal,
          txnName: btEnvironment,
        });
      } catch (error) {
        console.log("Error triggering bt", error);
      }
    }
  };

  const prepareViewPromotion = (triggered) => {
    let DOMready = false;
    let dataDivs = document.querySelectorAll(`[data-div]`);

    if (dataDivs.length == 0 && triggered == false) {
      setTimeout(async () => {
        dataDivs = document.querySelectorAll(`[data-div]`);
        if (attemptDiv < 15 && DOMready == false) {
          attemptDiv++;

          if (dataDivs.length > 0) {
            DOMready = true;
            setGtmElements(document.querySelectorAll(`[data-div]`));
          } else {
            prepareViewPromotion(triggered);
          }
        }
      }, 500);
    } else {
      setGtmElements(document.querySelectorAll(`[data-div]`));
    }
  };

  const sendGtmEvent = (fromLoader) => {
    if (!isGTMPDP) {
      if (loaded == false) {
        if (fromLoader) { //Pages based on loaders
          loaded = true;
          triggerGTMPageLoad();
          endBTTEvent();
        } else {
          //No loader then compare pages
          const DepartmentRegex = new RegExp(`/b/([^/]+)`);

          const path = window.location.pathname;
          loaded = true;

          const params = path.split("/");
          let detectProduct = 0;

          if (params.length > 2) {
            detectProduct = params[2].split("-").length - 1;
          }

          //Landings
          if (DepartmentRegex.test(path)) {
            const getHero = document.querySelector(".uilib-c-hero");
            const sideBar = document.querySelector(".facets--styledGrid-sideBar");

            if (getHero && !sideBar) {
              setTimeout(async () => {
                triggerGTMPageLoad();
                endBTTEvent();
              }, 500);
            } else {
              setTimeout(async () => {
                loaded = false;

                if (attempts < 15) {
                  attempts++;
                  sendGtmEvent(false);
                }else{
                  triggerPageViewLogic();
                  if ( !(loaderOpenState === false && prevLoaderState === true)) {
                    dispatchPageLoad();
                  }
                }
              }, 500);
            }
          } 
          else { //Other pages
            if(!verifyPage(window.location.pathname)){
              setTimeout(async () => {
                const dataDivs = document.querySelectorAll(`[data-div]`);

                if (attempts < 15 && dataDivs.length < 1 ) {
                  loaded = false;
                  attempts++;
                  sendGtmEvent(false);
                }else{
                  if(dataDivs.length == 1 && dataDivs[0] instanceof HTMLElement && dataDivs[0].getAttribute("data-div")?.includes("Ticker")){
                    attempts++;
                    sendGtmEvent(false);
                  }else{
                    prepareViewPromotion(isLoaded);
                    triggerPageViewLogic();
                    dispatchPageLoad();
                  }
                }
              }, 500);
            }
            endBTTEvent();
          }
        }
      }
    } else {
      if (partNumberBT && categoryBT && categoryBT2 ) {
        if (fromLoader) {  //Promotion tag based on loader logic
          prepareViewPromotion(isLoaded);
        }
        if (!pageLoadTriggered.current && prevURL.current === "") {
        setIsGTMLoaded(true);
        window["dataLayer"].push({
          event: "page_load",
          ecommerce: {
            sku: partNumberBT,
            item_category: categoryBT,
            item_category2: categoryBT2,
            item_category3: categoryBT3,
          },
        });

        const page_load = new CustomEvent("page_load", {
          detail: {
            sku: partNumberBT,
            item_category: categoryBT,
            item_category2: categoryBT2,
            item_category3: categoryBT3,
          },
          bubbles: true,
          cancelable: true,
          composed: false,
        });
        triggerPageViewLogic();
        dispatchEvent(page_load);
        pageLoadTriggered.current = true;
        endBTTEvent();
        }
      }
    }
  };

  const triggerPageViewLogic = () =>{
    if(sessionError && (sessionError?.errorKey !=='ERR_PARTIAL_AUTHENTICATION_NOT_ALLOWED'
      && sessionError?.errorKey !=='ERR_COOKIE_EXPIRED') && !pageviewTriggered.current){
      triggerPageView(mySite);
      if(window.location.href.includes('/b/') ){
        triggerConversionsPinterestViewCategory(category,mySite?.storeID);
      }
    }
    pageviewTriggered.current = true;
    prevURL.current = window.location.pathname;
  }

  const verifyPage = (path) => {
    const HomeRegex = new RegExp(`/$`);
    const CheckouRegex = new RegExp(`/thank-you$`);
    const ShoppingCartRegex = new RegExp(`/cart$`);
    const CategoryRegex = new RegExp(`/b/([^/]+)/([^/]+)/?$`);
    const SubCategoryRegex = new RegExp(`/b/([^/]+)/([^/]+)/([^/]+)/?$`);
    const ProductDetailsRegex = new RegExp(`/p/`);

    return path === "/HDM-B2C-Store"
      ? true
      : ShoppingCartRegex.test(path)
      ? true
      : CategoryRegex.test(path)
      ? true
      : SubCategoryRegex.test(path)
      ? true
      : ProductDetailsRegex.test(path)
      ? true
      : HomeRegex.test(path)
      ? true
      : CheckouRegex.test(path)
      ? true
      : false;
  };

  return <></>;
};
